/****   request.js   ****/

// 导入axios
import Vue from 'vue'
import axios from 'axios'
// axios.defaults.withCredentials = true; // 允许请求的时候带上cookie
// 使用element-ui Message做消息提醒
import { Message, Loading } from 'element-ui';
// 引用router
import router from './router/index'
import store from "@/vuex/store"

export function get(url, params) {
    return service.get(url, {
        params
    });
}

export function post(url, data) {
    return service.post(url, data);
}
export function del(url) {
    return service.delete(url);
}
export function put(url, data) {
    return service.put(url, data);
}
var loadingIndex = null;
//1. 创建新的axios实例，
const service = axios.create({
    // WsUrl: 'wss://api.wfsever.cn',
    // WsUrl: 'ws://localhost:3001',
    // 公共接口--这里注意后面会讲


    // baseURL: 'https://api.wfsever.cn',
    baseURL: `${store.state.Config.Api_Url}`,
    // baseURL: 'http://localhost:3001',

    // 超时时间 单位是ms，这里设置了3s的超时时间

    timeout: 30 * 1000

})
// 创建全局loading

// 2.请求拦截器

service.interceptors.request.use(config => {
    // loading = vm.$loading({
    //     lock: true,
    //     text: '数据加载中...',
    //     spinner: 'el-icon-loading',
    //     background: 'rgba(0, 0, 0, 0.7)'
    // })

    //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
//    console.log('请求拦截器中', loadingIndex);
    if (loadingIndex == null) {
        loadingIndex = Loading.service({
            fullscreen: true,
            text: '数据加载中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
    }

    config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
    let userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};
    config.headers.Authorization = 'Bearer ' + userInfo.Token;
    config.headers['Content-Type'] = 'application/json'; //配置请求头 如果这里不配置，后台接收不到参数或者收到参数不正确 将value当成了key
    return config;
}, error => {
    if (loadingIndex !== null) {
        loadingIndex.close()
        loadingIndex = null;
    }
    console.log('错误')
    Promise.reject(error)
})



// 3.响应拦截器

service.interceptors.response.use(response => {

    //接收到响应数据并成功后的一些共有的处理，关闭loading等
//    console.log('全局响应拦截中', response);
  //  console.log('开始关闭loading', loadingIndex);
    if (loadingIndex !== null) {
        loadingIndex.close()
        loadingIndex = null;
    }

    return response
}, error => {
    if (loadingIndex !== null) {
        loadingIndex.close()
        loadingIndex = null;
    }

    // console.log('error', error.response.status, error)

    /***** 接收到异常响应的处理开始 *****/
    if (error && error.response) {
        // 1.公共错误处理
        // 2.根据响应码具体处理
        console.log(error);
        switch (error.response.status) {
            case 400:
                error.message = '错误请求'
                break;
            case 401:
                // error.message = '未授权，请重新登录'
                Message.error('未授权，请重新登录')
                router.push({ path: "/" });
                break;
            case 402:
                error.message = '用户不存在'
                break
            case 403:
                error.message = '拒绝访问'
                break;
            case 404:
                error.message = '请求错误,未找到该资源'
                // window.location.href = "/"
                break;
            case 405:
                error.message = '请求方法未允许'
                break;
            case 408:

                error.message = '请求超时'

                break;

            case 500:

                error.message = '服务器端出错'

                break;

            case 501:

                error.message = '网络未实现'

                break;

            case 502:

                error.message = '网络错误'

                break;

            case 503:

                error.message = '服务不可用'

                break;

            case 504:

                error.message = '网络超时'

                break;

            case 505:

                error.message = 'http版本不支持该请求'

                break;

            default:

                error.message = `连接错误${error.response.status}`

        }

    } else {

        // 超时处理

        if (JSON.stringify(error).includes('timeout')) {

            Message.error('服务器响应超时，请刷新当前页')

        }

        Message.error('连接服务器失败')

    }

    Message.error(error.message)

    /***** 处理结束 *****/

    //如果不需要错误处理，以上的处理过程都可省略

    return Promise.resolve(error.response)

})

//4.导入文件

export default service