<template>
  <div>
    <div class="my-login-box">
      <h2>晚风·后台管理系统</h2>
      <form>
        <span id="erro" style="
                  color: red;
                  text-align: center;
                  font-size: 5px;
                  padding-left: 100px;
                  display: none;
                "></span>

        <div class="user-box">
          <input type="text" v-model="logininfo.Mobile" required="" />
          <label>Username</label>
        </div>
        <div class="user-box">
          <input type="password" v-model="logininfo.Password" @keyup="keyu" required="" />
          <label>Password</label>
        </div>
        <a href="#" @click="Employee">
          <span></span>
          <span></span>
          <span></span>
          <span></span> Login
        </a>
      </form>
    </div>
  </div>
</template>
<script>
import md5 from "js-md5";
// import * as Api from "@/Api";
import * as Api from "@/Api";
// 引入store
import { Message } from "element-ui";
export default {
  data() {
    return {
      logininfo: {
        Mobile: "",
        Password: "",
      },
      time: "",
    };
  },

  beforeCreate() {
    // 请求login/checkToken校验token是否有效
    // 判断localStorage.token是否为空
    if (localStorage.token == undefined || localStorage.token == null) {
      Message.error("请登陆");
    } else {
      this.$http.get("/login/checkToken").then((response) => {
        if (response.data.result === true) {
          // token有效 跳转到主页 并给予 欢迎回来的提示
          this.$router.push({ path: "/index" });
          Message.success("欢迎回来");
        } else {
          // token无效 停留在当前页面等待登陆
        }
      });
    }
  },
  methods: {
    // 定义登陆方法
    keyu(e) {
      if (e.keyCode == 13) {
        this.Employee();
      }
    },
    async Employee() {
      let zz = /\s+/g;
      // 进行去空格处理
      let data = this.logininfo;
      data.Password = data.Password.replace(zz, "");
      if (data.Mobile != "" && data.Password != "") {
        localStorage.clear();
        let res = await Api.login({
          type: 1,
          Mobile: data.Mobile,
          Password: md5(data.Password),
        });
        if (res.data.code == 200) {
          // 设置store中的user信息
          this.$store.commit("setuser", res.data.data[0]);
          // 设置store中的token
          // store.commit('setToken', res.data.data.token);
          Message.success("登陆成功");
          this.$webSocket.connect();
          // 使用pubsub发布hello消息
          // this.$router.push({ name: 'index', params: { id: res.data.message } });
          // 跳转路由到index页面
          this.$router.push({ path: "/index" });
        } else {
          Message.error(res.data.msg);
        }
      } else {
        Message.error("账号或密码不可为空");
      }
    },
  },
  created() {
    this.time = new Date().getTime();
    localStorage.clear();
  },
  mounted() {
    this.$webSocket.close();
  },
};
</script>

<style scoped>
/* scoped 让style样式 局部生效*/
/* 设置页面背景色 */
body {
  background-color: rgb(27, 42, 63);

}

.my-login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  padding: 40px;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}

.my-login-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
}

.my-login-box .user-box {
  position: relative;
}

.my-login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}

.my-login-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
}

.my-login-box .user-box input:focus~label,
.my-login-box .user-box input:valid~label {
  top: -30px;
  left: 0;
  color: #03e9f4;
  font-size: 12px;
}

.my-login-box form a {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 40px;
  letter-spacing: 4px;
  left: 100px;
}

.my-login-box a:hover {
  background: #03e9f4;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4, 0 0 100px #03e9f4;
}

.my-login-box a span {
  position: absolute;
  display: block;
}

.my-login-box a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }

  50%,
  100% {
    left: 100%;
  }
}

.my-login-box a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  animation: btn-anim2 1s linear infinite;
  animation-delay: 0.25s;
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }

  50%,
  100% {
    top: 100%;
  }
}

.my-login-box a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  animation: btn-anim3 1s linear infinite;
  animation-delay: 0.5s;
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }

  50%,
  100% {
    right: 100%;
  }
}

.my-login-box a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  animation: btn-anim4 1s linear infinite;
  animation-delay: 0.75s;
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }

  50%,
  100% {
    bottom: 100%;
  }
}
</style>
