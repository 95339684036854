var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-container',[_c('el-header',{attrs:{"height":""}},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"80px","inline":false,"size":"normal"}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":3,"offset":0}},[_c('el-form-item',{attrs:{"label":"选择客户","size":"normal"}},[_c('el-select',{attrs:{"value-key":"rjid","placeholder":"请选择客户","clearable":"","filterable":""},on:{"change":_vm.selectrjid},model:{value:(_vm.form.rjid),callback:function ($$v) {_vm.$set(_vm.form, "rjid", $$v)},expression:"form.rjid"}},_vm._l((_vm.dta.rjid),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1),_c('el-col',{attrs:{"span":3,"offset":0}},[_c('el-form-item',{attrs:{"label":"选择门店","size":"normal"}},[_c('el-select',{attrs:{"value-key":"dm","placeholder":"请选择店名","clearable":"","filterable":""},model:{value:(_vm.form.dm),callback:function ($$v) {_vm.$set(_vm.form, "dm", $$v)},expression:"form.dm"}},_vm._l((_vm.dta.dm),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1),_c('el-col',{attrs:{"span":3,"offset":0}},[_c('el-form-item',{attrs:{"label":"档口:","size":"normal"}},[_c('el-select',{attrs:{"value-key":"dk","placeholder":"请选择档口","clearable":"","filterable":""},model:{value:(_vm.form.dk),callback:function ($$v) {_vm.$set(_vm.form, "dk", $$v)},expression:"form.dk"}},_vm._l((_vm.dta.dk),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1)],1)],1)],1),_c('el-container',[_c('el-main',{attrs:{"height":""}},[_c('vxe-grid',_vm._b({ref:"grid",scopedSlots:_vm._u([{key:"img",fn:function(ref){
var row = ref.row;
return undefined}},{key:"pager",fn:function(){return [_c('vxe-pager',{attrs:{"layouts":[
              'Sizes',
              'PrevJump',
              'PrevPage',
              'Number',
              'NextPage',
              'NextJump',
              'FullJump',
              'Total' ],"current-page":_vm.agentList.pagerConfig.currentPage,"page-size":_vm.agentList.pagerConfig.pageSize,"total":_vm.agentList.pagerConfig.total},on:{"page-change":_vm.handlePageChange}})]},proxy:true},{key:"toolbar_buttons",fn:function(){return [_c('div',{staticStyle:{"padding-left":"9px"}},[_c('el-button',{attrs:{"type":"warning","size":"small","icon":"el-icon-close"},on:{"click":_vm.AddOrder}},[_vm._v("添加记录")]),_c('el-button',{attrs:{"type":"warning","size":"small","icon":"el-icon-close"},on:{"click":_vm.Save}},[_vm._v("保存")])],1)]},proxy:true},{key:"SearchName",fn:function(ref){
            var row = ref.row;
return [_c('el-select',{ref:"select",attrs:{"remote":"","remote-method":_vm.serchName,"value-key":"name","placeholder":"请输入关键字","clearable":"","filterable":""},on:{"change":function($event){return _vm.SelGoods($event, row)}},model:{value:(row.name),callback:function ($$v) {_vm.$set(row, "name", $$v)},expression:"row.name"}},_vm._l((_vm.dta.GoodsOption),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)]}},{key:"Remark",fn:function(ref){
            var row = ref.row;
return [_c('el-input',{attrs:{"placeholder":"特殊备注填写此处","size":"normal","clearable":""},on:{"change":function($event){}},model:{value:(row.remark),callback:function ($$v) {_vm.$set(row, "remark", $$v)},expression:"row.remark"}})]}}])},'vxe-grid',_vm.agentList,false))],1),_c('el-footer',{attrs:{"height":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }