<template>
  <div>
    <!-- <el-header class="header">
        <el-image
          class="logo"
          src="https://admin.wfsever.cn/image/toplogo.png"
          fit="fill"
          :lazy="true"
        ></el-image>

        <el-dropdown class="header__user-dropdown">
          <el-image
            class="header__user-avatar"
            src="https://wfsever.cn/glht/img/logo.jpg"
            fit="fill"
            :lazy="true"
          ></el-image>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>用户资料</el-dropdown-item>
            <el-dropdown-item @click.native="LoginOut">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-header> -->
    <el-container>
      <!-- 主页左侧菜单栏 -->
      <el-aside width="atuo" style="background-color: #fff">
        <menus :menusData="menusData"></menus>
      </el-aside>
      <!-- 页面主体区域 -->
      <el-container style="border: 1px solid #626262; height: 100vh; border-right: 20%">
        <el-main>
          <tabs :tabsData="this.$store.state.tabs"></tabs>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import menus from "./menus.vue";
import home from "./home.vue";
import tabs from "./tabs.vue";
import * as Api from "@/Api";
// 引入md5加密
import md5 from "js-md5";
export default {
  components: {
    menus,
    home,
    tabs,
  },
  data() {
    return {
      isCollapse: false,
      imgurl: "",
      menusData: [
        //菜单列表
      ],
      loadingLock: false,
      // 是否允许websocket自动重连
      WebsocketAuto: true,
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    // 消息接收回调函数
    async RaderMsg(MsgId) {
      console.log("已读回调消息Id", MsgId);
      await Api.Config.ReadMessage({ MsgId }).then((res) => {
        console.log(res);
      });
    },
    // localSocket() {
    //   console.log("localSocket");
    //   let that = this;
    //   if ("WebSocket" in window) {
    //     // console.log("您的浏览器支持 WebSocket!");
    //     // location.host
    //     // console.log(that.$store.state.userInfo);
    //     let MToken = md5(that.$store.state.userInfo.Token);
    //     that.ws = new WebSocket(
    //       // `wss://${that.$http.defaults.WsUrl}/socket/${that.$store.state.userInfo.Token}`
    //       `${that.$http.defaults.WsUrl}/socket/${that.$store.state.userInfo.Token}`
    //     );
    //     that.$websocket.setWs(that.ws);
    //     that.ws.onopen = function () {
    //       console.log("websocket连接成功");
    //       console.log(that.$websocket);
    //       console.log(that.ws);
    //       Api.Config.GetMessageList().then((res) => {
    //         console.log(res);
    //         // that.$websocket.setMsgList(res.data);//SETMsgList
    //       });
    //       Api.Config.GetAllMessageList().then((res) => {
    //         console.log("完整消息列表", res);
    //         // that.$websocket.setMsgList(res.data);//SETMsgList
    //       });
    //       // websocket 连接成功后发送login类型的消息并附带token 用于让服务器验证token是否有效 达到绑定用户的目的
    //       // that.ws.send(
    //       //   JSON.stringify({
    //       //     type: "login",
    //       //     Token: that.$store.state.userInfo.Token,
    //       //   })
    //       // );
    //     };

    //     that.ws.onclose = function (res, event) {
    //       // 关闭 websocket
    //       console.log("连接已关闭...", res);
    //       console.log(event);
    //       if (res.code == 4001) {
    //         that.$message.error("您的账号被重复登陆，请刷新页面重新登录");
    //       } else if (this.WebsocketAuto) {
    //         //断线重新连接
    //         setTimeout(() => {
    //           that.localSocket();
    //         }, 2000);
    //       }
    //     };
    //     // 接收服务端数据时触发事件
    //     that.ws.onmessage = function (res) {
    //       console.log("websocket 收到消息", res);
    //       let data = JSON.parse(res.data);
    //       // 此处进行消息通知类型的处理 如上线通知工作通知等同时播放远程音频
    //       const audio = new Audio();
    //       audio.src = "https://wfsever.cn/Audio/message.mp3";
    //       switch (data.type) {
    //         // 收到消息类型为close断开 且状态码等于4001 判定为当前用户被服务器踢下线不需要自动重连
    //         case "close":
    //           if (data.State === 4001) {
    //             that.WebsocketAuto = false;
    //           }
    //           break;
    //         case "msg":
    //           audio.play();
    //           // 消息通知 弹出Notification 通知
    //           that.$notify({
    //             title: data.title,
    //             message: data.msg,
    //             type: "info",
    //             duration: 3000, //
    //             onClose: () => {
    //               // 关闭通知时触发
    //               that.RaderMsg(data.MsgId);
    //             },
    //           });
    //           break;
    //         case "online":
    //           audio.play();
    //           // 上线通知 弹出Notification 通知
    //           that.$notify({
    //             title: data.title,
    //             message: data.msg,
    //             type: "info",
    //             duration: 3000, //
    //           });
    //           break;
    //         case "heart":
    //           // 心跳检测
    //           let msg = {
    //             type: "heart",
    //             msg: "在呢在呢我还在呢",
    //           };
    //           // 将对象转换为字符串
    //           that.ws.send(JSON.stringify(msg));
    //           break;

    //         default:
    //           break;
    //       }
    //     };
    //   } else {
    //     // 浏览器不支持 WebSocket
    //     console.log("您的浏览器不支持 WebSocket!");
    //     this.openNotificationWithIcon(
    //       "error",
    //       "浏览器",
    //       "您的浏览器不支持显示消息请更换",
    //       1,
    //       1
    //     );
    //   }
    // },
  },
  mounted() {
    // this.connectWebsocket(); //连接websocket
    // console.log(this.$websocket);
    // this.localSocket();
    this.$store.commit("clearMsgList");
    Api.Config.GetAllMessageList().then((res) => {
      console.log("完整消息列表", res);
      this.$store.commit("PushMsgList", res.data.data);
      // that.$websocket.setMsgList(res.data);//SETMsgList
    });
  },
  beforeCreate() {
    // 从localStorage中获取user对象
    this.user = JSON.parse(localStorage.getItem("userInfo"));
    this.$store.commit("setuser", JSON.parse(localStorage.getItem("userInfo")));
    // 判断user是否存在 如果不存在则跳转到登录页面
    if (!this.user) {
      this.$router.push({ path: "/" });
    }

    // 请求login/checkToken校验token是否有效
    Api.checkToken().then((res) => {
      if (res.data.result === true) {
        // token有效
      } else {
        // token无效
        this.$router.push({ name: "/login" });
      }
    });
    // this.$http.get("/login/checkToken").then((response) => {
    //   if (response.data.result === true) {
    //     // token有效
    //   } else {
    //     // token无效
    //     this.$router.push({ name: "/login" });
    //   }
    // });
    this.loadingLock = true;
    // 请求menu/getMenu获取菜单数据
    Api.GetMenus().then((res) => {
      this.menusData = res.data.menus;
    });
    this.loadingLock = false;
  },
  beforeUnmount() {
    // 关闭websocket
    this.WebsocketAuto = false;
    this.ws.close();
  },
};
</script>

<style scoped></style>
