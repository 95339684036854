<template>
  <div>
    消息通知发布窗口
    <el-form :model="form" ref="form" label-width="80px" :inline="false" size="normal">
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form-item label="通知类型:" size="normal">
            <el-select
              v-model="msg.type"
              value-key="msgtype"
              placeholder="请选择通知类型"
              clearable
              filterable
            >
              <el-option
                v-for="item in dta.msgtype"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form-item label="范围：" size="normal">
            <el-switch
              active-text="指定人"
              inactive-text="所有人"
              v-model="msg.scope"
              :active-value="true"
              :inactive-value="false"
            >
            </el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="10" :offset="0">
          <el-form-item label="接收人" size="normal">
            <el-select
              multiple
              collapse-tags
              :disabled="!msg.scope"
              v-model="msg.ReceiveId"
              value-key="msgReceiveId"
              placeholder="请选择接收人"
              clearable
              filterable
            >
              <el-option
                v-for="item in dta.UserList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="10" :offset="0">
          <el-form-item label="通知标题" size="normal">
            <el-input
              required
              style="width: 200px"
              type="text"
              v-model="msg.Title"
              placeholder="请输入通知标题"
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 通知内容 -->
        <el-col :span="20" :offset="0">
          <el-form-item label="通知内容" size="normal">
            <el-input
              type="textarea"
              v-model="msg.Content"
              placeholder="请输入通知内容"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即创建</el-button>
        <el-button>取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import * as Api from "@/Api";
export default {
  data() {
    return {
      msg: {
        type: "",
        scope: false, //通知范围 true 指定人 false 所有人 当为false的时候 后端不需要再处理接收人
        // ReceiveId: [], //接收人 数组类型
        Title: "", //通知标题
        Content: "", //通知内容
        ReceiveId: [], //接收人id
      },
      dta: {
        UserList: [],
        msgtype: [],
      },
      form: {
        // 表单数据
      },
    };
  },
  methods: {
    // 定义函数
    // 提交表单
    async onSubmit() {
      if (this.msg.scope) {
        // 指定人
        if (this.msg.ReceiveId.length == 0) {
          this.$message.error("请选择接收人");
          return;
        } else {
          //   this.msg.ReceiveId = this.msg.ReceiveId.join(",");
          Api.SendMsgToUserList(this.msg).then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              this.$message.success("发布成功");
            } else {
              this.$message.error("发布失败");
            }
          });
          // let res = await this.$http.post("/MessAge/SendMsgToUserList", this.msg);
          // console.log(res);
          // if (res.data.code == 200) {
          //   this.$message.success("发布成功");
          // } else {
          //   this.$message.error("发布失败");
          // }
        }
      } else {
        // 所有人
        this.msg.ReceiveId = [];
        Api.SendMsgToAll(this.msg).then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.$message.success("发布成功");
          } else {
            this.$message.error("发布失败");
          }
        });
        // let res = await this.$http.post("/MessAge/SendMsgToAll", this.msg);
        // console.log(res);
        // if (res.data.code == 200) {
        //   this.$message.success("发布成功");
        // } else {
        //   this.$message.error("发布失败");
        // }
      }
    },
    // 获取消息类型列表
    async getMessageList() {
      let res = await Api.GetMsgTypeList();
      // let res = await this.$http.get("/cnfig/PushTypeList");
      //   console.log(res);
      this.dta.msgtype = res.data.data;
      //   this.dta.msgtype = res.data;
      //   console.log(this.messageList);
    },
    async getUserList() {
      let res = await Api.GetUserListVL();
      // let res = await this.$http.get("/cnfig/GetStaffList");
      console.log(res);
      this.dta.UserList = res.data.Result;
    },
  },
  beforeCreate() {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用
  },
  created() {
    // 在实例创建完成后被立即调用。在这一步，实例已完成以下的配置：数据观测(data observer)，属性和方法的运算，watch/event 事件回调。然而，挂载阶段还没开始，el 属性目前不可见。
  },
  beforeMount() {
    // 在挂载开始之前被调用：相关的 render 函数首次被调用。
  },
  mounted() {
    this.getMessageList();
    this.getUserList();
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
  },
  beforeUpdate() {
    // 数据更新时调用，发生在虚拟 DOM 打补丁之前。这里适合在更新之前访问现有的 DOM，比如手动移除已添加的事件监听器。
  },
  updated() {
    // 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
  },
  beforeDestroy() {
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
  },
  destroyed() {
    // Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解除绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。
  },
  activated() {
    // keep-alive 组件激活时调用。
  },
  deactivated() {
    // keep-alive 组件停用时调用。
  },
  errorCaptured() {
    // 当捕获一个来自子孙组件的错误时被调用。
  },
  render() {
    // 2.6.0+ 新增
    // 该钩子允许你在 createElement 被调用之后，返回虚拟节点之前对其进行修改。这里，你可以修改虚拟节点，或者把它换成其它的。
  },
  renderError() {
    // 2.6.0+ 新增
    // 该钩子会在 render 函数、或其它任何被 Vue 实例使用的函数 (比如 watcher 的回调) 抛出错误时被调用。该钩子会接收两个参数：错误本身和包含错误来源信息的组件实例。
  },
};
</script>

<style scoped>
/* scoped 让style样式 局部生效*/
</style>
