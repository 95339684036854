<template>
  <div class="msgmain">
    <div class="title">
      <div
        class="tab"
        :style="{ 'background-color': tab_index == 0 ? '#2d8cf0' : '#999' }"
        @click="
          {
            {
              tab_index = 0;
            }
          }
        "
      >
        通知消息
      </div>
      <div
        class="tab"
        :style="{ 'background-color': tab_index == 1 ? '#2d8cf0' : '#999' }"
        @click="
          {
            {
              tab_index = 1;
            }
          }
        "
      >
        待办事项
      </div>
    </div>
    <div style="position: relative; height: 500px" v-scrollBar>
      <el-collapse
        v-show="tab_index == 0"
        style="padding: 5px"
        accordion
        shadow="hover"
        :key="item.id"
        v-for="(item, index) in $store.state.MsgList"
        @change="RaderMsg(item)"
      >
        <el-collapse-item>
          <template slot="title" style="width: calc(100%-10px)">
            <!-- 一致性 Consistency<i class="header-icon el-icon-info"></i> -->
            <span :style="{ color: item.is_read ? '#999' : '#409EFF' }">
              <i
                v-if="item.is_read == 0"
                class="el-icon-chat-dot-round"
                style="font-size: 18px"
                :style="{ color: item.is_read ? '#999' : '#409EFF' }"
              ></i
              >{{ " " + item.id + ":" + item.title + "   " + b(item.created_at) }}</span
            >
          </template>
          <div class="text">
            <div class="content">{{ item.content }}</div>
            <div class="time">推送时间:{{ a(item.created_at) }}</div>
          </div>
        </el-collapse-item>
      </el-collapse>
      <!-- <el-collapse
        v-show="tab_index == 1"
        style="padding: 5px"
        accordion
        shadow="hover"
        :key="item.id"
        v-for="(item, index) in $store.state.MsgList"
      >
        <el-collapse-item>
          <template slot="title" style="width: calc(100%-10px)">
            <span>
              <i
                class="el-icon-chat-dot-round"
                style="font-size: 18px"
                :style="{ color: item.is_read ? '#999' : '#409EFF' }"
              ></i
              >{{ " " + item.id + ":" + item.title }}</span
            >
          </template>
          <div class="text">
            <div class="content">{{ item.content }}</div>
            <div class="time">推送时间:{{ a(item.created_at) }}</div>
          </div>
        </el-collapse-item>
      </el-collapse> -->
    </div>
  </div>
</template>

<script>
// 引入dayjs
import dayjs from "dayjs";
import * as Api from "@/Api";
export default {
  data() {
    return {
      tab_index: 0, //tab切换 0为通知消息 1为待办事项
    };
  },
  methods: {
    // 面板点击事件
    handleChange(e) {
      console.log(e);
    },
    // 消息已读回调
    RaderMsg(item) {
      if (item.is_read == 1) return;
      let MsgId = item.id;
      console.log("已读回调消息Id", MsgId);
      Api.Config.ReadMessage({ MsgId }).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          // this.$message.success("消息已读");
          console.log(this.$store);
          this.$store.commit("ReadMsg", MsgId);
        }
      });
    },
    // 定义函数
    // 格式化当前时间
    a(e) {
      // 使用deyjs格式化时间
      return dayjs(e).format("YYYY-MM-DD HH:mm:ss");
    },
    // 格式化时间返回日期
    b(e) {
      return dayjs(e).format("YYYY-MM-DD");
    },
  },
};
</script>

<style scoped lang="less">
/* scoped 让style样式 局部生效*/
.text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  padding: 6px;
  // margin-bottom: 18px;
  .content {
    height: 80px;
  }
  .time {
    height: 20px;
    font-size: 12px;
    color: #999;
    padding-bottom: 0px;
  }
}

.msgmain {
  width: 280px;
  height: 500px;
  // background-color: #fff;
  // background-color: red;
  border-radius: 5px;
  // 边框
  border: 1px solid #ebeef5;
  .title {
    display: flex;
    .tab {
      width: 50%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      color: #fff;
      // background-color: #999;
      border-radius: 5px;
    }
  }
}
</style>
