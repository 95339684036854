<template>
  <div style="background-color: #fff">
    <div class="imgdiv" v-if="!isCollapse">
      <el-dropdown
        :hide-on-click="false"
        placement="bottom"
        style="position: absolute; right: 10px"
      >
        <el-badge is-dot :hidden="!hasUnreadNotification" ref="bellContainer">
          <a
            class="el-icon-message-solid Bell"
            :class="{ 'bell-has-unread': hasUnreadNotification }"
            style="color: #585858"
            href="javascript:void(0)"
            v-on:mouseover="isAnimate = true"
            v-on:mouseleave="isAnimate = false"
            @click="showNotificationList = !showNotificationList"
          >
          </a>
        </el-badge>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item> 这里带上这个 通知中心就会变成一个菜单子项目 左右会出现边框 并且鼠标移动进去后会出现背景色 所以取消-->
          <NotificationList></NotificationList>
          <!-- </el-dropdown-item> -->
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown @command="handleCommand">
        <el-image :src="user.image" fit="fill" :lazy="true"></el-image>
        <el-dropdown-menu slot="dropdown" style="text-align: right">
          <el-dropdown-item command="userInfo">用户资料</el-dropdown-item>
          <el-dropdown-item command="LoginOut">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div>
        <span>欢迎您:{{ user.name }}</span>
      </div>
    </div>
    <el-menu
      default-active="1-4-1"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      :collapse="isCollapse"
      :unique-opened="true"
    >
      <el-submenu
        v-for="submenus in menusData"
        :index="submenus.subid"
        :key="submenus.id"
      >
        <template slot="title">
          <i :class="submenus.icon"></i>
          <span>{{ submenus.menuName }}</span>
        </template>
        <!-- 上面是顶级菜单加载 -->
        <!-- 下面是上级菜单加载 -->
        <el-submenu
          v-for="Firstlevel in submenus.children"
          :index="Firstlevel.subid"
          :key="Firstlevel.id"
          v-if="Firstlevel.menuType == 1"
        >
          <!-- 展示上级菜单名称 -->
          <template slot="title">
            <i :class="Firstlevel.icon"></i>
            <span>{{ Firstlevel.submenu }}</span>
          </template>
          <!-- 下级菜单加载 -->
          <el-menu-item
            v-if="Secondlevel.menuType == 2"
            v-for="Secondlevel in Firstlevel.children"
            :index="Secondlevel.subid"
            :key="Secondlevel.id"
            @click="tabsset(Secondlevel)"
          >
            <template slot="title">
              <i :class="Secondlevel.icon"></i>
              <span>{{ Secondlevel.submenu }}</span>
            </template>
          </el-menu-item>
        </el-submenu>

        <!-- 底级菜单加载 -->
        <el-menu-item
          v-for="item in submenus.children"
          :index="item.subid"
          :key="item.subid"
          @click="tabsset(item)"
          v-if="item.menuType == 2"
        >
          <i :class="item.icon"></i>
          <span>{{ item.submenu }}</span>
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>
<script>
import NotificationList from "./NotificationList.vue";
export default {
  components: {
    //注册通知列表组件
    NotificationList,
  },

  // 通过props接收父组件传递过来的数据
  props: {
    menusData: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      showNotificationList: false,
      hasUnreadNotification: true,
      user: {},
      screenWidth: document.body.clientWidth,
      isCollapse: false,
      screenWidth: null,
      dialogWidth: 0,
    };
  },
  methods: {
    handleCommand(command) {
      switch (command) {
        case "userInfo":
          break;
        case "LoginOut":
          this.LoginOut();
          break;
        default:
          break;
      }
    },
    LoginOut() {
      // 弹出确认提示框 是否退出登陆
      this.$confirm("是否退出登陆", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 点击确定后执行的操作
          localStorage.clear();
          this.$router.push("/");
        })
        .catch(() => {
          // 点击取消后执行的操作
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    tabsset(item) {
      this.$store.commit("addTabs", item);
    },
  },
  mounted() {
    this.bellContainerHeight = this.$refs.bellContainer.clientHeight;
    this.bellContainerWidth = this.$refs.bellContainer.clientWidth;
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    this.user = JSON.parse(localStorage.getItem("userInfo"));
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
  watch: {
    screenWidth: {
      handler: function (val, oldVal) {
        //handler是一个函数，val新值，oldVal旧值
        //val新值，oldVal旧值
        console.log(val, oldVal);
        if (val <= 1500) {
          this.isCollapse = true;
          // this.dialogWidth = "400px";
          //此刻屏幕宽度小于800
        } else {
          this.isCollapse = false;
          // this.dialogWidth = "800px";
          //此刻屏幕宽度大于800
        }
      },
    },
  },
};
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.el-image {
  cursor: pointer;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  /* 添加过渡动画 */
  transition: all 1s;
}
.el-image:hover {
  transform: scale(1.1);
  /* display: flex; */
}
.imgdiv {
  /* display: flex;
  justify-content: center; */
  /* flex-wrap: wrap; */
  background-color: #fff;
  text-align: center;
  /* margin-top: 20px; */
  padding-top: 20px;
  position: relative;
}
/* .el-badge {
  position: absolute;
  right: 10px;
} */
.Bell {
  text-decoration: none;
  /* position: absolute; */
  font-size: 24px;
}
.Bell:hover {
  /* 执行动画shake-top  0.5秒一次 */
  animation: shake-top 0.5s;
  /* 无限循环动画 */
  animation-iteration-count: infinite;
}
/* 当有新通知的时候铃铛变为红色并且开始闪烁 */
.bell-has-unread {
  color: red !important;
  animation: blink 1s linear infinite;
  animation-iteration-count: infinite;
}
/* 有未读通知闪烁动画 */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes shake-top {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
  }
  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}
</style>
