import Vue from "vue";
import Vuex from "vuex";
import { Message } from "element-ui";

Vue.use(Vuex);

const store = new Vuex.Store({
  // 定义数据
  state: {
    Config: {
      // Api_Url: "http://server.cn",
      // Socket_Url: "ws://server.cn",
      // Api_Url: "http://localhost:3001",
      // Socket_Url: "ws://localhost:3001",
      Api_Url: 'https://api.wfsever.cn',
      Socket_Url: `wss://Api.wfsever.cn`,
    },
    MsgList: [], //消息列表
    tabIndex: "sa1d32a1f234a65f6a",
    fromdisabled: false,
    Goods: {
      //商品列表
      CustomerList: [], //客户列表
      GoodsList: [], //商品列表
      pidList: [], //PID列表
      GoodsType: [], //商品类型列表
      unitoptions: [
        //商品单位列表
        { value: "斤", label: "斤" },
        { value: "公斤", label: "公斤" },
        { value: "支", label: "支" },
        { value: "个", label: "个" },
        { value: "份", label: "份" },
        { value: "只", label: "只" },
        { value: "板", label: "板" },
        { value: "包", label: "包" },
        { value: "箱", label: "箱" },
        { value: "袋", label: "袋" },
        { value: "瓶", label: "瓶" },
        { value: "台", label: "台" },
        { value: "桶", label: "桶" },
        { value: "件", label: "件" },
        { value: "根", label: "根" },
        { value: "米", label: "米" },
        { value: "卷", label: "卷" },
        { value: "捆", label: "捆" },
        { value: "扎", label: "扎" },
        { value: "盒", label: "盒" },
        { value: "罐", label: "罐" },
      ],
    },
    tabs: [
      {
        title: "首页",
        name: "sa1d32a1f234a65f6a",
        component: () => import(`@/views/home.vue`),
      },
    ],
    userInfo: {},
    userform: {
      id: "",
      name: "",
      Mobile: "",
      Authority: "",
      OpenId: "",
      Status: "",
      Online: "",
      image: "",
    },
    permissions: [],
  },
  // 定义函数
  mutations: {
    PushMsgList(state, data) {
      // 判断传来的data是单个数组还是多个数组 然后再push给state.MsgList
      if (data instanceof Array) {
        state.MsgList.push(...data); //...data是将数组展开
      } else {
        state.MsgList.push(data);
      }
    },
    // 清空消息列表
    clearMsgList(state) {
      state.MsgList = [];
    },
    ReadMsg(state, data) {
      // 这里接收到的是一个消息的id 根据这个id改变MsgList中的指定数组的is_read属性
      console.log("开始执行消息已读", data);
      state.MsgList.forEach((item) => {
        if (item.id == data) {
          console.log(item);
          item.is_read = 1;
        }
      });
    },
    setSuppList(state, data) {
      state.Goods.SuppList = data;
    },
    // 设置PidList数据 将数据存入state
    setPidList(state, data) {
      state.Goods.pidList = data;
    },
    // 设置客户数据 将数据存入state
    setCustomerList(state, data) {
      state.Goods.CustomerList = data;
    },
    // 设置商品数据 将数据存入state
    setGoodsList(state, data) {
      state.Goods.GoodsList = data;
    },
    // 设置商品类型数据 将数据存入state
    setGoodsType(state, data) {
      state.Goods.GoodsType = data;
    },
    // 设置用户信息并保存到localStorage中
    setuser(state, userInfo) {
      state.userInfo = userInfo;
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      // localStorage.setItem('token', userInfo.Token)
    },
    // 定义form表单是否可编辑
    setfromdisabled(state, fromdisabled) {
      state.fromdisabled = fromdisabled;
    },
    // 定义用户管理表单数据
    setuserform(state, userform) {
      state.userform = userform || {
        id: "",
        name: "",
        Mobile: "",
        Authority: "",
        OpenId: "",
        Status: "",
        Online: "",
        image: "",
      };
    },
    // 定义delTabs方法
    delTabs(state, name) {
      if (state.tabs.length === 1) {
        Message.error("至少保留一个标签页");
        return;
      } else {
        state.tabs = state.tabs.filter((item) => item.name !== name);
      }
    },
    // 定义settabsindex方法
    settabIndex(state, index) {
      state.tabIndex = index;
    },

    //根据item._id新增tabs 如果存在则更改tabsindex的选中项
    async addTabs(state, item) {
      let tabs = state.tabs;
      let index = tabs.findIndex((i) => i.name == item.subid);
      // 检查item.path的路径是否已注册过组件
      // let isExist = router.options.routes.some((i) => i.path == item.path)
      // Vue.component(item.path, () => import(`../views/${item.path}.vue`))
      if (index == -1) {
        tabs.push({
          name: item.subid,
          title: item.submenu,
          // content: item.path
          // content: () => import(`${item.path}`),
          component: () => import(`@/views/${item.path}`),
        });
        state.tabIndex = item.subid;
      } else {
        state.tabIndex = item.subid;
      }
    },
  },
});
export default store;
