<template>
  <div>
    <el-container>
      <el-header height="">
        <!-- 代客下单页面参考小人物大哥的代下单和22城的代下单功能 -->
        <!-- q:代客下单的页面应该是什么样子的 -->
        <!-- a:代客下单的页面应该是一个表格，表格的列应该是：商品名称、商品编码、商品规格、商品单位、商品数量、商品单价、商品金额、商品备注 -->
        <el-form :model="form" ref="form" label-width="80px" :inline="false" size="normal">
          <el-row :gutter="20">
            <el-col :span="3" :offset="0">
              <el-form-item label="选择客户" size="normal">
                <el-select v-model="form.rjid" value-key="rjid" placeholder="请选择客户" clearable filterable
                  @change="selectrjid">
                  <el-option v-for="item in dta.rjid" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3" :offset="0">
              <el-form-item label="选择门店" size="normal">
                <el-select v-model="form.dm" value-key="dm" placeholder="请选择店名" clearable filterable>
                  <el-option v-for="item in dta.dm" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3" :offset="0">
              <el-form-item label="档口:" size="normal">
                <el-select v-model="form.dk" value-key="dk" placeholder="请选择档口" clearable filterable>
                  <el-option v-for="item in dta.dk" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- 头部代码 -->
      </el-header>
      <el-container>
        <!-- 主窗口代码 -->
        <el-main height="">
          <vxe-grid v-bind="agentList" ref="grid">
            <template #img="{ row }">
              <!-- <el-image :src="row.img" fit="fill" :lazy="true"></el-image> -->
            </template>
            <template #pager>
              <vxe-pager :layouts="[
                'Sizes',
                'PrevJump',
                'PrevPage',
                'Number',
                'NextPage',
                'NextJump',
                'FullJump',
                'Total',
              ]" :current-page="agentList.pagerConfig.currentPage" :page-size="agentList.pagerConfig.pageSize"
                :total="agentList.pagerConfig.total" @page-change="handlePageChange">
              </vxe-pager>
            </template>
            <template #toolbar_buttons>
              <div style="padding-left: 9px">
                <el-button type="warning" size="small" icon="el-icon-close" @click="AddOrder">添加记录</el-button>
                <el-button type="warning" size="small" icon="el-icon-close" @click="Save">保存</el-button>
              </div>
            </template>
            <template #SearchName="{ row }">
              <el-select ref="select" remote :remote-method="serchName" v-model="row.name" value-key="name"
                placeholder="请输入关键字" clearable filterable @change="SelGoods($event, row)">
                <el-option v-for="item in dta.GoodsOption" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </template>
            <template #Remark="{ row }">
              <el-input v-model="row.remark" placeholder="特殊备注填写此处" size="normal" clearable @change=""></el-input>


            </template>
          </vxe-grid>
        </el-main>
        <el-footer height="">
          <!-- 底部代码 -->
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import * as Api from "@/Api";
// import dayjs from "dayjs";
export default {
  methods: {
    // 搜索物资
    serchName(e) {
      console.log(e);
      if (e) {
        Api.Goods.Search({ value: e, rjid: this.form.rjid }).then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.dta.GoodsOption = res.data.Goods
          }
        }).catch((err) => {
          console.log(err);
        });
      }

    },
    SelGoods(e, row) {
      // console.log(e, row);
      let item = this.dta.GoodsOption.filter((item) => {
        if (item.id == e) {
          return item
        }
      })[0]
      // console.log(item);
      row.img = item.img
      row.name = item.name
      row.unit = item.unit
      row.wzid = item.id
      // row.remark = ''
      row.price = item.dj
      row.subtotal = 0
      row.inventory = 999
      row.dm = this.form.dm
      row.dk = this.form.dk
      row.rjid = this.form.rjid
      row.state = 0
      this.$nextTick(() => {
        this.$refs.grid.setEditCell(row, 'quan'); // 设置当前行
      });
    },
    SearchList() {
      // 查询请求
    },

    Save() {
      // 保存请求
      let OrderList = this.$refs.grid.getInsertRecords()
      console.log(OrderList);
    },
    AddOrder() {
      // 添加记录
      this.$refs.grid.insertAt({}, -1).then(({ row }) => {
        let temp = this.$refs.grid.getInsertRecords().length;
        if (temp == 1) {
          this.$refs.grid.scrollToRow(row); // 滚动到新增行
        }
      });



      // this.$refs.grid.insert({}).then(({ row }) => {
      //   this.$refs.grid.scrollToRow(row);// 滚动到新增行
      // });
    },
    selectrjid(e) {
      // 选择客户事件触发 获取指定客户的物资库及门店
      //获取客户物资列表
      Api.Goods.GetGoodsListByRjid({ rjid: e }).then((res) => {
        // console.log(res);
        if (res.data.code == 200) {
          this.dta.GoodsList = res.data.data
        }
      });
      console.log(e);
      // 根据客户id获取门店
      Api.Cust.GetStoreList({ rjid: e }).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.dta.dm = res.data.shopName
        }
      });
      Api.Cust.GetStallList({ rjid: e }).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.dta.dk = res.data.dk
        }
      });
    },
    // 获取rjid
    GetRjid() {
      Api.Cust.GetCustListLL().then((res) => {
        if (res.data.code == 200) {
          this.dta.rjid = res.data.Customer
        }
      });

    },
    handlePageChange(val) {
      // 分页
      this.agentList.pagerConfig.currentPage = val.currentPage;
      this.agentList.pagerConfig.pageSize = val.pageSize;
      this.SearchList();
    },

  },
  mounted() {
    this.GetRjid();
  },
  data() {
    return {
      // 代客下单
      form: {
        rjid: "",
        dm: "",
        dk: "",
      },
      dta: {
        GoodsOption: [],
        GoodsList: [],
        rjid: [],
        dm: [],
        dk: [],
      },
      agentList: {
        border: true,//是否显示边框
        resizable: true,//是否可以拖动列宽调整大小
        showOverflow: true,//是否显示超出部分
        keepSource: true,//是否保留源数据
        printConfig: {},//打印配置
        importConfig: {},//导入配置
        exportConfig: {},//导出配置
        pagerConfig: {//分页配置
          //分页配置
          total: 0,
          currentPage: 1,
          perfect: true,
          pageSize: 30,
        },
        checkboxConfig: {
          reserve: true,
          highlight: true,
          range: true,
          // 是否显示复选框
          //   checkMethod: (params) => {
          //     return params.row.State != -1 && params.row.State != 2;
          //   },
        },
        keyboardConfig: {
          isArrow: true,
          isDel: true,
          isEnter: true,
          isTab: true,
          isEdit: true,
          // isChecked: true,//是否支持选中
        },
        mouseConfig: {
          selected: true,//是否支持选中
        },
        editConfig: {
          //编辑配置
          trigger: "dblclick",
          mode: "cell",
          showStatus: true, 
          autoClear: true, 
          autoselect: true, 
          immediate: false, 
          insert_actived: true, 
          autofocus: true,
        },
        align: "center",
        toolbarConfig: {
          slots: {
            // 自定义工具栏模板
            buttons: "toolbar_buttons",
          },
          perfect: true,//是否显示完美按钮
          refresh: {
            //刷新按钮
            // 阻止默认操作 改为自定义刷新操作
            query: () => {
              this.SearchList();
            },
            icon: "vxe-icon-refresh",
          },
          // import: {
          //   //导入按钮
          //   icon: "vxe-icon-upload",
          // },
          export: {
            //导出按钮
            icon: "vxe-icon-download",
          },
          print: {
            //  打印按钮
            icon: "vxe-icon-print",
          },
          zoom: {
            //  缩放按钮
            iconIn: "vxe-icon-fullscreen",
            iconOut: "vxe-icon-minimize",
          },
          custom: {
            //  自定义列按钮
            icon: "vxe-icon-menu",
          },
        },
        columns: [
          { type: "seq", title: "序号", width: 50 },
          // { title: "操作", slots: { default: "actions" } },
          { field: "wzid", title: "物资编码", visible: true },
          { field: "img", title: "图片", width: 50, slots: { default: "img" } },
          // {
          //   field: 'name',
          //   title: '名称',
          //   editRender: {
          //     name: '$input',
          //     props: {
          //       type: 'select',
          //       filterable: true,
          //       remote: true,
          //       remoteMethod: async (option, row) => {
          //         const res = await Api.Goods.Search();
          //         console.log(res);
          //         if (res.data.code == 200) {
          //           res.results.map(item => {
          //             return { value: item.id, label: item.name };
          //           });
          //         }
          //       }
          //     }
          //   }
          // },



          {
            field: "name", title: "名称", slots: { default: 'SearchName' },
          },
          { field: "unit", title: "单位" },
          {
            field: "quan",
            title: "数量",
            editRender: {
              name: '$input',
              props: { type: 'float', digits: 2, placeholder: '请输入数量' },
              events: {
                change: (params) => {
                  console.log(params);
                  console.log(this.$refs.grid.getRowSeq(params.row));
                  console.log('当前行', params.$rowIndex);
                  // 这里 params.$rowIndex 是当前行的索引
                  // 获取临时新增的行数
                  let temp = this.$refs.grid.getInsertRecords().length - 1;
                  console.log(temp, params.$rowIndex);
                  if (params.$rowIndex >= temp && params.row.wzid) {
                    this.AddOrder();
                  }
                },
                closed: (params) => {
                  this.AddOrder();
                  console.log('关闭事件被触发了', params);
                },
              }
            },
          },

          {
            field: "remark", title: "备注",
            // slots: { default: 'Remark' },
            editRender: {
              name: '$input', props: { type: 'text', placeholder: '请输入备注' },
              events: {
                blur: ({ row, column, cell }, event) => {
                  // 触发单元格结束编辑事件
                  console.log('blur事件被触发了', row, column, cell, event);
                  let inputValue = event.value;
                  this.$refs.grid.updateStatus({ row, column, cell }, inputValue);
                },
                'edit-closed': ({ row, column, cellValue }) => {
                  console.log('编辑结束，值为', cellValue);
                  console.log('当前行数据', row);
                  console.log('当前列配置', column);
                }
              }
            }
          },
          { field: "price", title: "单价" },
          {
            field: "subtotal", title: "小计",
            slots: {
              default: ({ row }) => {
                row.subtotal = row.quan * row.price
                return `${row.quan * row.price}元`
              }
            }
          },
          { field: "inventory", title: "库存" },
          { field: "dm", title: "门店" },
          { field: "dk", title: "档口" },
          { field: "rjid", title: "客户" },
          { field: "state", title: "状态" },
          { field: "id", title: "订单ID" },
        ],
        data: [],
      },
    };
  },
};
</script>

<style scoped>
/* scoped 让style样式 局部生效*/
</style>
