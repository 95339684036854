import { get, post, put, del } from "./http";
// 登录
export const login = (p) => post("/login", p);
// 校验Token
export const checkToken = (p) => get("/login/checkToken", p);
// 获取菜单列表
export const GetMenus = (p) => get("/menu/getMenu", p);
// 发送消息给指定用户列表
export const SendMsgToUserList = (p) => post("/MessAge/SendMsgToUserList", p);
// 推送消息给所有人
export const SendMsgToAll = (p) => post("/MessAge/SendMsgToAll", p);
// 获取消息类型列表
export const GetMsgTypeList = (p) => get("/cnfig/PushTypeList", p);
// 获取用户列表
export const GetUserList = (p) => get("/cnfig/GetStaffList", p);
// 获取用户列表 value:用户ID label:用户名称 方式返回
export const GetUserListVL = (p) => get("user/GetEmployeeListByPosition", p);
// 根据职务获取用户列表 Position参数为职务名称
export const GetDriverList = (p) => post("/cnfig/GetEmployeeListByPosition", p);
// 获取销售账单列表
export const GetSalesList = (p) => post("/bili/getSaleList", p);
// 获取物资库标识列表
export const GetCustomerList = (p) => get("/cnfig/GetMaterialtList", p);

// 获取供货商列表
// export const GetSuppList = p => get("/supp/getSuppList", p);
// 搜索门店SearchStore
export const SearchStore = (p) => post("/Cust/SearchStore", p);
// 更新门店数据 UpdateStore
export const UpdateStore = (p) => post("/Cust/UpdateStore", p);
// 获取客户数据列表
export const GetCustList = (p) => get("/Cust/GetCustList", p);
export const Stock = {
  // 获取盘点记录 可分页查询 返回data字段
  GetStockList: (p) => post("/Record/GetStockList", p),
  // 加载盘点模板 返回data字段
  GetStockTemplate: (p) => post("/Record/GetStockTemplate", p),
  // 盘点完成 保存盘点结果 返回data字段
  SaveStock: (p) => post("/Record/SaveStock", p),
  // 审核盘点单
  CheckStock: (p) => post("/Record/CheckStock", p),
  // 更新盘点单数据
  UpdateStock: (p) => post("/Record/UpdateStock", p),
};
export const Goods = {
  // 定义CheckId方法POST方式校验goodsid是否可用
  CheckId: (p) => post("/goods/checkId", p),
  // 新增商品
  AddGoods: (p) => post("/goods/addGoods", p),
  // 更新商品数据
  UpdateGoods: (p) => post("/Goods/updateGoods", p),
  // 分页请求商品列表
  GetGoodsListByPage: (p) => post("/goods/getGoodsListByPage", p),
  // 获取商品类别列表
  GetGoodsTypeList: (p) => get("/goods/getGoodsTypeList", p),
  // 获取所有商品列表
  GetGoodsList: (p) => get("/cnfig/GetGoodsList", p),
  // 根据物资编码更新单价 updateGoodsPrice 可接受数组
  UpdateGoodsPrice: (p) => post("/Goods/updateGoodsPrice", p),
  // 根据客户id rjid获取客户商品列表 参数 rjid 返回data字段
  GetGoodsListByRjid: (p) => post("/goods/getGoodsListByRjid", p),
  // 搜索商品 提供一个value值和rjid值 value值可为编码 缩写名称任意一种 后端有类型判断根据不同类型进行不同处理
  // 返回Goods数组对象 完整物资数据
  Search: (p) => post("/goods/search", p),
};
// Pid类方法
export const Pid = {
  // 获取Pid列表 value:Pid label:anme 方式返回
  GetPidList: (p) => get("/cnfig/GetPidList", p),
  // 请求一个新的可用的Pid码
  GetNewPid: (p) => get("/cnfig/GetMaxPmaterial", p),
  // 新增Pid
  AddPid: (p) => post("/Pid/addPid", p),
  // 更新Pid数据
  UpdatePid: (p) => post("/Pid/updatePid", p),
  // 搜索Pid
  SearchPidListByPage: (p) => post("/Pid/GetPidList", p),
  // 根据Pid取出Pid数据 返回result字段
  GetPiddataByPid: (p) => post("/Pid/GetPiddataByPid", p),
};
// 供应商类接口
export const Supp = {
  // 获取供货商列表 res.data.SuppList SuppId SupplierName
  GetSuppList: (p) => get("/supp/getSuppList", p),
  //Value:id Label:供货商名称的方式返回 res.data.data
  GetSuppListVL: (p) => get("/cnfig/GetSuppList", p),
  // 获取供应商指定日期的报账明细并返回一个Money作为总金额
  // 该接口只能查询当前用户的数据
  getSuppMonthMoney: (p) => post("/Supp/getSuppMonthMoney", p),
  // 获取供应商报账数据 传入 年月参数 如202301
  // 该接口只能查询当前用户的数据
  getSuppMonthData: (p) => post("/Supp/getSuppMonthData", p),
};
// 客户数据接口
export const Cust = {
  // 获取客户数据列表
  GetCustList: (p) => get("/Cust/GetCustList", p),
  // 编辑客户信息
  EditCust: (p) => post("/Cust/EditCust", p),
  //   新增客户信息
  AddCust: (p) => post("/Cust/AddCust", p),
  // value和label都是客户名称的方式返回 res.data.Customer
  GetCustListLL: (p) => get("/customer/getCustomerList", p),
  // 根据rjid获取店名列表
  GetStoreList: (p) => post("/customer/GetShopName", p),
  // 根据rjid和店名获取档口列表
  GetStallList: (p) => post("/customer/getDk", p),
  // 更新门店信息
  UpdateStore: (p) => post("/Cust/UpdateStore", p),
};
// 菜单数据接口
export const Menu = {
  // 新增菜单
  AddMenu: (p) => post("/menu/addMenu", p),
  // 更新菜单
  UpdateMenu: (p) => post("/menu/editMenu", p),
  // 获取所有菜单
  GetMenuAllList: (p) => get("/menu/getMenuAll", p),
  // 删除菜单
  DeleteMenu: (p) => post("/menu/delMenus", p),
  // 搜索菜单
  SearchMenu: (p) => post("/menu/searchMenu", p),
};
// 员工/用户数据接口
export const User = {
  // 获取权限列表 当前列表仅用于Authority字段 UserGroup用户组织字段待完善
  GetAuthorityList: (p) => get("/cnfig/EmployeeDutyList", p),
  // 获取用户组织列表
  GetUserGroupList: (p) => get("/cnfig/GetUserOrgList", p),
  // 获取员工列表
  GetEmployeeList: (p) => get("/user/GetEmployeeList", p),
  // 更新员工数据
  UpdateEmployee: (p) => post("/user/UpdateEmployee", p),
  // 获取员工负责门店列表
  GetEmployeeStoreList: (p) => post("/user/GetEmployeeSuppList", p),
  // 获取用户列表 value:用户ID label:用户名称 方式返回
  GetUserListVL: (p) => get("user/GetEmployeeListByPosition", p),
  // 新增用户
  AddEmployee: (p) => post("/user/AddEmployee", p),
  // 校验用户手机号
  CheckMobile: (p) => post("/user/CheckMobile", p),
};
export const Record = {
  // 搜索库存变动记录
  SearchRecord: (p) => post("/Record/getRecordList", p),
  // 新增入库记录
  AddInRecord: (p) => post("/Record/addRecord", p),
  // 获取上次采购价格
  GetLastPrice: (p) => post("/Record/getPurchasePrice", p),
  // 库存记录上报
  ReportRecord: (p) => post("/Record/addRecord", p),
  // 根据指定供货商id获取供货商库存数据
  GetSuppStock: (p) => post("/Record/GetSuppStock", p),
  // 根据Pid或关键字获取库存数据
  GetStockByParentName: (p) => post("/Record/GetStockByParentName", p),
};
// 出库数据接口
export const Out = {
  // 获取出库记录 数据对象data
  GetOutRecord: (p) => post("/Record/getOutRecord", p),
  // 更新出库数据
  UpdateOutRecord: (p) => post("/Record/updateOutRecord", p),
  // 审核出库数据
  CheckOutRecord: (p) => post("/Record/checkOutRecord", p),
};

// 入库数据接口
export const Ware = {
  // 获取供应商入库模板数据
  GetSuppInWare: (p) => post("/Ware/GetSuppSaleData", p),
  // 更新入库数据
  UpdateInWare: (p) => post("/ware/UpdateWare", p),
  // 入库数据审核
  CheckInWare: (p) => post("/ware/WareCheck", p),
  // 搜索入库记录分页方式
  SearchInWare: (p) => post("/ware/GetWareList", p),
};
// 配置数据接口
export const Config = {
  // 获取出入库类型列表
  GetInOutTypeList: (p) => get("/cnfig/RecordTypeList", p),
  // 获取入库类型列表
  GetInTypeList: (p) => get("cnfig/GetInType", p),
  // 根据物资id取出Pid
  GetPidByGoodsId: (p) => post("/cnfig/GetPidByWzId", p),
  // 获取树形门店列表
  GetStoreTree: (p) => get("/Cust/GetTreeStore", p),
  // 消息已读回调
  ReadMessage: (p) => post("/MessAge/ReadMsg", p),
  // 获取指定用户未读消息列表
  GetMessageList: (p) => get("/MessAge/GetUserIdMsgNoRead", p),
  // 获取指定用户所有消息列表并根据is_read判断是否已读
  GetAllMessageList: (p) => get("/MessAge/GetUserIdMsgAll", p),
  // 获取物资库标识
  GetCustomerList: (p) => get("/cnfig/GetMaterialtList", p),
  // 获取送货单位列表
  GetDeliveryList: (p) => get("/cnfig/GetDeliveryList", p),
  // 获取商品转换类型列表
  GetGoodsConversion: (p) => get("/cnfig/GetGoodsConversion", p),
  // 获取二维码 qr-dataurl接口 提供connent参数作为二维码内容
  Qcdataurl: (p) => post("/cnfig/qcdataurl", p),
};
// 订单数据接口
export const Order = {
  // 更新订单数据接口
  UpdateOrder: (p) => post("/Order/updateOrder", p),
  // 更新订单状态接口
  updateState: (p) => post("/Order/updateState", p),
  // 删除订单接口
  DeleteOrder: (p) => post("/Order/DelOrder", p),
  // 搜索订单接口
  SearchOrder: (p) => post("/Order/getOrderListByDate", p),
  // 根据供货商ID和客户ID、Pid获取销售明细
  GetSaleDetail: (p) => post("/Order/GetSaleDetail", p),
  // 下单接口
  AddOrder: (p) => post("/Order/addOrder", p),
  // 刷新订单数据接口
  RefreshOrder: (p) => post("/Order/RefreshOrder", p),
};
// 调度接口 调度司机门店 调度供货商
export const Dispatch = {
  // 获取门店司机调度列表
  getShopList: (p) => post("scheduling/getShopList", p),
};
// 销售及盈利接口
export const Vlzt = {
  // 获取客户销售及盈利数据
  getTotalSalesByDate: (p) => post("/Vlzt/getTotalSalesByDate", p),
  // 获取指定客户的门店销售及盈利数据
  getTotalSalesByDateAndRjid: (p) =>
    post("/Vlzt/getTotalSalesByDateAndRjid", p),
  // 分页方式获取指定日期的亏损销售数据
  getLossSalesByPage: (p) => post("/Vlzt/getLossSalesByPage", p),
  // 刷新采购及盈利数据
  refresh: (p) => post("/Vlzt/refresh", p),
};

export const Bill = {
  // 报账单状态更新
  UpdateSuppBillState: (p) => post("/bili/UpdateSuppBillState", p),
  // 获取供货商报账数据
  GetSuppBillList: (p) => post("/bili/SuppBillList", p),
  // 更新供货商报账数据
  UpdateSuppBill: (p) => post("/bili/UpdateSuppBill", p),
  // 新增供货商报账单
  SuppBillADD: (p) => post("/bili/SuppBillADD", p),
  // 获取客户账单列表
  GetCustBillList: (p) => post("/bili/GetCustBillList", p),
};
