let socket = null;
let WebsocketAuto = true;
import { Notification } from 'element-ui'
import * as Api from "@/Api";
import store from "@/vuex/store"
// console.log('vuex', mapState.state);
const webSocket = {
    // 如果组件缺失了install方法，Vue将会抛出一个警告。并报错
    install(Vue) {
        Vue.prototype.$webSocket = this
        console.log(store.state.Config.Socket_Url);
    },
    connect(url) {
        // let Socket_Url = `${store.state.Config.Socket_Url}/socket/${store.state.userInfo.Token}`
        let Socket_Url = `${store.state.Config.Socket_Url}/socket/logs/Api`
        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
            this.socket = new WebSocket(url ?? Socket_Url)
            this.socket.addEventListener('open', event => {
                // 这里是连接完成 考虑是否需要将链接成功后的websocket对象存储到本地 防止每次刷新页面时 导致websocket链接丢失问题
                console.log('WebSocket connected:', event);
                this.socket.addEventListener('close', event => {
                    console.log('WebSocket closed:', event);
                    if (this.WebsocketAuto) {
                        this.connect(url);
                    }
                })
            });
            // 注册错误事件
            this.socket.addEventListener('error', event => {
                console.log('WebSocket error:', event);
            });
            // 注册消息事件
            this.socket.addEventListener('message', event => {
                let that = this
                let data = JSON.parse(event.data);
                console.log("websocket 收到消息", event);
                switch (data.type) {
                    // 收到消息类型为close断开 且状态码等于4001 判定为当前用户被服务器踢下线不需要自动重连
                    case "close":
                        if (data.State === 4001) {
                            this.WebsocketAuto = false;
                        }
                        break;
                    case "msg":
                        // audio.play();
                        // 消息通知 弹出Notification 通知
                        store.commit("PushMsgList", data);
                        Notification({
                            title: data.title,
                            message: data.msg,
                            type: "info",
                            duration: 3000, //
                            onClose: () => {
                                // 关闭通知时触发
                                RaderMsg(data.MsgId);
                            },
                        });
                        break;
                    case "online":
                        // audio.play();
                        // 上线通知 弹出Notification 通知
                        Notification({
                            title: data.title,
                            message: data.msg,
                            type: "info",
                            duration: 3000, //
                        });
                        break;
                    case "heart":
                        // 心跳检测
                        let msg = {
                            type: "heart",
                            msg: "在呢在呢我还在呢",
                        };
                        // 将对象转换为字符串
                        this.send(JSON.stringify(msg));
                        break;

                    default:
                        break;
                }
            });
        }
    },
    send(message) {
        if (this.socket) {
            // 判断message是对象还是字符串 如果为对象则转换为字符串
            if (typeof message === "object") {
                message = JSON.stringify(message);
            }
            this.socket.send(message)
        } else {
            console.log('send方法', 'websocket未连接')
            if (this.WebsocketAuto) {
                this.connect();
            }
        }
    },
    receive(callback) {//receive方法用于接收websocket消息
        if (this.socket) {
            this.socket.addEventListener('message', event => {
                // socket.addEventlistener的事件有open、message、error、close
                callback(event.data)
                let data = JSON.parse(event.data);
                // 此处进行消息通知类型的处理 如上线通知工作通知等同时播放远程音频
                const audio = new Audio();
                audio.src = "https://wfsever.cn/Audio/message.mp3";



            })
        } else {
            console.log('WebSocket is not connected.')
        }
    },

    close() {//主动关闭
        if (this.socket) {
            this.WebsocketAuto = false
            this.socket.close()
            console.log('WebSocket closed.')
        } else {
            console.log('WebSocket is not connected.')
        }
    }


}

function RaderMsg(MsgId) {
    console.log("已读回调消息Id", MsgId);
    Api.Config.ReadMessage({ MsgId }).then((res) => {
        console.log(res);
    });
};

export default webSocket;