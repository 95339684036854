<template>
  <div>
    <el-tabs type="card" closable v-model="$store.state.tabIndex" @tab-remove="tabremove">
      <el-tab-pane
        v-for="item in tabsData"
        :label="item.title"
        :key="item.subid"
        :name="item.name"
      >
        <components :is="item.component"> </components>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  // 通过props传递数据
  props: {
    tabsData: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  methods: {
    // 定义移除tabs事件
    tabremove(targetName) {
      console.log(targetName);
      let tabs = this.tabsData;
      let activeName = this.$store.state.tabIndex;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }
      this.$store.commit("delTabs", targetName);
      this.$store.commit("settabIndex", activeName);
    },

    handleTabsEdit(targetName, action) {
      console.log(targetName, action);
      //    定义删除tabs事件

      let tabs = this.tabsData;
      let activeName = this.$store.state.tabIndex;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }
      this.$store.commit("delTabs", targetName);
      this.$store.commit("settabIndex", activeName);
    },
  },
};
</script>
