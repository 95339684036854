import Vue from 'vue'
import App from './App.vue'
import ECharts from 'vue-echarts'
// 引入响应式布局css
import "element-ui/lib/theme-chalk/display.css";
// 引入store
import store from './vuex/store.js';
// 引入 Elmenent 组件库
import ElementUI from 'element-ui';
//引入vxe-table
import VXETable from 'vxe-table'
import 'vxe-table/lib/index.css'
import 'element-ui/lib/theme-chalk/index.css';
// 引入dayjs用于处理时间日期问题
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/zh-cn";

dayjs.extend(relativeTime);
Vue.prototype.$dayJS = dayjs;
Vue.component('v-chart', ECharts)
// 注册echarts组件
// Vue.component('ECharts', Echarts)
// 引入vue-perfect-scrollbar滚动条组件
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// 引入scrollbar滚动条
import '@/components/scrollbar'
// 引入 websocket
import webSocket from '@/websocket'
Vue.use(webSocket)
// Vue.use(WebSocketPlugin)


import router from './router'
// 引入axios 请求服务
import http from './http'
// 加载ElementUI组件库
Vue.use(ElementUI);
// 加载vxe-table组件库
Vue.use(VXETable)
// 加载vue-perfect-scrollbar滚动条组件
Vue.use(VuePerfectScrollbar)

// 加载axios模块
Vue.prototype.$http = http
Vue.prototype.$store = store
Vue.prototype.$ElementUI = ElementUI
Vue.config.productionTip = false
// 自定义指令scoped 用来解决给popover组件添加动画时，样式丢失的问题
// 同时也可以用来解决其他组件的样式丢失问题 给组件添加scopedId
Vue.directive('scoped', function (element, binding) {
  const scopedId = binding.value.$options._scopeId
  if (!scopedId) {
    return
  }

  const componentTag = element.__vue__.$vnode.tag
  let target = [] // 需要添加scopedId的元素

  if (componentTag.indexOf('ElPopover') !== -1) {
    // popover组件
    target.push(element.children[0])
  } else {
    target.push(element)
  }
  target.forEach(item => { item.setAttribute(scopedId, '') })

})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

VXETable.setup({
  // size: null, // 全局尺寸
  // zIndex: 999, // 全局 zIndex 起始值，如果项目的的 z-index 样式值过大时就需要跟随设置更大，避免被遮挡
  // version: 0, // 版本号，对于某些带数据缓存的功能有用到，上升版本号可以用于重置数据
  loadingText: '数据加载中...', // 全局loading提示内容，如果为null则不显示文本
  table: {
    highlightHoverRow: true, // 鼠标移到行是否要高亮显示
    //   showHeader: true,// 是否显示表头
    //   keepSource: false,// 是否保留源数据，如果为 true 则在数据更新后可以通过 $table.getSourceData() 获取到更新前的数据
    //   showOverflow: null,// 当内容过长时显示为省略号
    //   showHeaderOverflow: null,// 当表头内容过长时显示为省略号
    //   showFooterOverflow: null,// 当表尾内容过长时显示为省略号
    //   size: null,// 表格尺寸
    //   autoResize: false,// 自动监听父容器尺寸变化去重新计算表格
    //   stripe: false,// 是否带斑马纹
    //   border: false,// 是否带有纵向边框
    //   round: false,// 是否带有圆角边框
    //   emptyText: '暂无数据',// 空数据时显示的文本内容
    //   rowConfig: {// 行配置项
    //     keyField: '_X_ROW_KEY' // 行数据的唯一主键字段名
    //   },
    //   radioConfig: {// 单选框配置项
    //     trigger: 'default'// 单选框触发方式
    //   },// 
    //   checkboxConfig: {// 多选框配置项
    //     strict: false,// 是否严格模式，严格模式下只能通过 checkbox-config.checked 属性来勾选数据
    //     highlight: false,// 是否要高亮当前选中的行
    //     range: false,// 是否开启多选框的范围选择功能
    //     trigger: 'default'// 多选框触发方式
    //   },
    //   sortConfig: {// 排序配置项
    //     remote: false,// 是否服务端排序
    //     trigger: 'default',// 排序触发方式
    //     orders: ['asc', 'desc', null],// 排序的顺序
    //     sortMethod: null// 自定义排序的方法
    //   },
    //   filterConfig: {// 筛选配置项
    //     remote: false,// 是否服务端筛选
    //     filterMethod: null// 自定义筛选的方法
    //   },
    //   expandConfig: {// 展开行配置项
    //     trigger: 'default',// 展开行触发方式
    //     showIcon: true// 是否显示展开图标
    //   },
    //   treeConfig: {// 树形结构配置项
    //     rowField: 'id',// 行数据的唯一主键字段名
    //     parentField: 'parentId',// 行数据的父节点字段名
    //     children: 'children',// 行数据的子节点集合字段名
    //     hasChild: 'hasChild',// 行数据的是否存在子节点字段名
    //     mapChildren: '_X_ROW_CHILD',// 行数据的子节点集合映射字段名
    //     indent: 20,// 层级缩进的宽度
    //     showIcon: true// 是否显示图标
    //   },
    //   tooltipConfig: {// tooltip 配置项
    //     enterable: true// 鼠标是否可进入到 tooltip 中
    //   },
    //   menuConfig: {// 快捷菜单配置项
    //     visibleMethod () {}// 快捷菜单显示的方法
    //   },
    //   editConfig: {// 单元格编辑配置项
    //     mode: 'cell',// 编辑模式
    //     showAsterisk: true// 是否显示必填字段的红色星号
    //   },
    //   importConfig: {// 导入配置项
    //     modes: ['insert', 'covering']// 导入模式
    //   },
    //   exportConfig: {// 导出配置项
    //     modes: ['current', 'selected']// 导出模式
    //   },
    //   customConfig: {// 自定义列配置项
    //    storage: false// 是否保存自定义列的状态
    //   },
    //   scrollX: {// 横向 X 可视渲染配置项
    //     gt: 60// 大于多少列启用
    //   },
    //   scrollY: {// 纵向 Y 可视渲染配置项
    //     gt: 100// 大于多少行启用
    //   },
    loading: {// 加载中配置项
      icon: 'vxe-icon-spinner roll',// 加载中的图标
      text: '加载中...'// 加载中的文本
    }
  },
  // grid: {// 表格配置项
  //   size: null,// 表格尺寸
  //   zoomConfig: {// 缩放配置项
  //     escRestore: true// 是否支持按下 ESC 键还原
  //   },
  //   pagerConfig: {// 分页配置项
  //     perfect: false// 是否开启分页优化
  //   },
  //   toolbarConfig: {// 工具栏配置项
  //     perfect: false// 是否开启工具栏优化
  //   },
  //   proxyConfig: {// 代理配置项
  //     autoLoad: true,// 是否自动加载数据
  //     message: true,// 是否显示加载中的提示
  //     props: {// 请求参数配置
  //       list: null, // 用于列表，读取响应数据
  //       result: 'result', // 用于分页，读取响应数据
  //       total: 'page.total' // 用于分页，读取总条数
  //     }
  //     beforeItem: null,// 在数据被添加到表格前的回调
  //     beforeColumn: null,// 在列被添加到表格前的回调
  //     beforeQuery: null,// 在查询数据前的回调
  //     afterQuery: null,// 在查询数据后的回调
  //     beforeDelete: null,// 在删除数据前的回调
  //     afterDelete: null,// 在删除数据后的回调
  //     beforeSave: null,// 在保存数据前的回调
  //     afterSave: null// 在保存数据后的回调
  //   }
  // },
  pager: {// 分页配置项
    size: null,// 分页尺寸
    autoHidden: false,// 是否自动隐藏分页
    perfect: true,// 是否开启分页优化
    pageSize: 30,// 每页大小
    pagerCount: 7,// 分页按钮的数量
    pageSizes: [30, 50, 100, 300, 500, 1000, 2000, 5000],// 每页大小选项列表
    layouts: ['PrevJump', 'PrevPage', 'Jump', 'PageCount', 'NextPage', 'NextJump', 'Sizes', 'Total']// 布局
  },
  // form: {// 表单配置项
  //   preventSubmit: false// 是否阻止表单的默认提交事件
  //   size: null,// 表单尺寸
  //   colon: false,// 是否显示标签的冒号
  //   validConfig: {// 校验配置项
  //     autoPos: true// 是否自动定位到第一个校验不通过的表单项
  //   },
  //   tooltipConfig: {// 提示框配置项
  //     enterable: true// 鼠标是否可进入到 tooltip 中
  //   },
  //   titleAsterisk: true// 是否显示标题的红色星号
  // },
  // input: {// 输入框配置项
  //   size: null,// 输入框尺寸
  //   transfer: false// 是否将属性转换为原生属性
  //   parseFormat: 'yyyy-MM-dd HH:mm:ss.SSS',// 解析日期的格式
  //   labelFormat: '',// 标签格式化
  //   valueFormat: '',// 值格式化
  //   startDay: 1,// 一周的开始日期
  //   digits: 2,// 数值精度
  //   controls: true// 是否显示输入框的控制按钮
  // },
  // textarea: {// 文本域配置项
  //   size: null// 文本域尺寸
  //   autosize: {// 自适应配置项
  //     minRows: 1,// 最小行数
  //     maxRows: 10// 最大行数
  //   }
  // },
  // select: {// 选择框配置项
  //   size: null,// 选择框尺寸
  //   transfer: false,// 是否将属性转换为原生属性
  //   optionConfig: {// 选项配置项
  //     keyField: '_X_OPTION_KEY' // 选项数据的唯一主键字段名
  //   },
  //   multiCharOverflow: 8// 多选时选中值的最大显示长度
  // },
  // toolbar: {// 工具栏配置项
  //   size: null,// 工具栏尺寸
  //   import: {// 导入配置项
  //     mode: 'covering'// 覆盖模式
  //   },
  //   export: {// 导出配置项
  //     types: ['csv', 'html', 'xml', 'txt','xlsx']// 导出类型
  //   },
  //   custom: {// 自定义配置项
  //     isFooter: true// 是否显示在表尾
  //   },
  //   buttons: [],// 自定义按钮列表
  //   tools: []// 自定义工具列表
  // },
  // button: {// 按钮配置项
  //   size: null,// 按钮尺寸
  //   transfer: false// 是否将属性转换为原生属性
  // },
  // radio: {// 单选框配置项
  //   size: null// 单选框尺寸
  // },
  // checkbox: {// 复选框配置项
  //   size: null// 复选框尺寸
  // },
  // switch: {// 开关配置项
  //   size: null// 开关尺寸
  // },
  // modal: {// 模态框配置项
  //   // size: null,// 模态框尺寸
  //   minWidth: 340,// 模态框最小宽度
  //   minHeight: 200,// 模态框最小高度
  //   lockView: true,// 是否锁住页面
  //   mask: true,// 是否显示遮罩
  //   duration: 3000,// 自动关闭的延时
  //   marginSize: 0,// 间距大小
  //   dblclickZoom: true,// 是否允许通过双击头部放大
  //   showTitleOverflow: true// 是否显示超出的标题
  //   storage: false// 是否缓存窗口状态
  // },
  // list: {// 列表配置项
  //   scrollY: {// 纵向虚拟滚动配置项
  //     gt: 100// 大于多少条数据启用
  //   }
  // }
})

