var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"msgmain"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"tab",style:({ 'background-color': _vm.tab_index == 0 ? '#2d8cf0' : '#999' }),on:{"click":function($event){{
          {
            _vm.tab_index = 0;
          }
        }}}},[_vm._v(" 通知消息 ")]),_c('div',{staticClass:"tab",style:({ 'background-color': _vm.tab_index == 1 ? '#2d8cf0' : '#999' }),on:{"click":function($event){{
          {
            _vm.tab_index = 1;
          }
        }}}},[_vm._v(" 待办事项 ")])]),_c('div',{directives:[{name:"scrollBar",rawName:"v-scrollBar"}],staticStyle:{"position":"relative","height":"500px"}},_vm._l((_vm.$store.state.MsgList),function(item,index){return _c('el-collapse',{directives:[{name:"show",rawName:"v-show",value:(_vm.tab_index == 0),expression:"tab_index == 0"}],key:item.id,staticStyle:{"padding":"5px"},attrs:{"accordion":"","shadow":"hover"},on:{"change":function($event){return _vm.RaderMsg(item)}}},[_c('el-collapse-item',[_c('template',{staticStyle:{"width":"calc(100%-10px)"},slot:"title"},[_c('span',{style:({ color: item.is_read ? '#999' : '#409EFF' })},[(item.is_read == 0)?_c('i',{staticClass:"el-icon-chat-dot-round",staticStyle:{"font-size":"18px"},style:({ color: item.is_read ? '#999' : '#409EFF' })}):_vm._e(),_vm._v(_vm._s(" " + item.id + ":" + item.title + " " + _vm.b(item.created_at)))])]),_c('div',{staticClass:"text"},[_c('div',{staticClass:"content"},[_vm._v(_vm._s(item.content))]),_c('div',{staticClass:"time"},[_vm._v("推送时间:"+_vm._s(_vm.a(item.created_at)))])])],2)],1)}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }