<template>
    <div>
        <h1>欢迎来到home首页</h1>
    </div>
</template>
<script>
export default {
    name: 'home',
    data() {
        return {

        }
    },
}
</script>
 
<style scoped>
/* scoped 让style样式 局部生效*/
</style>