<template>
  <div>
    <el-switch v-model="offoron" :active-value="true" :inactive-value="false" @change="">
    </el-switch>
    <div class="block">
    <span class="demonstration">红</span>
    <el-slider v-model="color_G"></el-slider>
  </div>
  <div class="block">
    <span class="demonstration">黄</span>
    <el-slider v-model="color_R"></el-slider>
  </div>
  <div class="block">
    <span class="demonstration">绿</span>
    <el-slider v-model="color_B"></el-slider>
  </div>
  </div>
</template>
<script>
// import mqtt from 'mqtt'
export default {
  data() {
    return {
      offoron: true,
      color_R: 0,
      color_G: 0,
      color_B: 0,

      testList: [],
    }
  },
  methods: {
    // 定义函数
  },
  beforeCreate() {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用
  },
  created() {
    // 在实例创建完成后被立即调用。在这一步，实例已完成以下的配置：数据观测(data observer)，属性和方法的运算，watch/event 事件回调。然而，挂载阶段还没开始，el 属性目前不可见。
  },
  beforeMount() {
    // 在挂载开始之前被调用：相关的 render 函数首次被调用。
  },
  mounted() {
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // mqtt.connect("open.wfsever.cn:11883")
  },
  beforeUpdate() {
    // 数据更新时调用，发生在虚拟 DOM 打补丁之前。这里适合在更新之前访问现有的 DOM，比如手动移除已添加的事件监听器。
  },
  updated() {
    // 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
  },
  beforeDestroy() {
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
  },
  destroyed() {
    // Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解除绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。
  },
  activated() {
    // keep-alive 组件激活时调用。
  },
  deactivated() {
    // keep-alive 组件停用时调用。
  },
  errorCaptured() {
    // 当捕获一个来自子孙组件的错误时被调用。
  },
  render() {
    // 2.6.0+ 新增
    // 该钩子允许你在 createElement 被调用之后，返回虚拟节点之前对其进行修改。这里，你可以修改虚拟节点，或者把它换成其它的。
  },
  renderError() {
    // 2.6.0+ 新增
    // 该钩子会在 render 函数、或其它任何被 Vue 实例使用的函数 (比如 watcher 的回调) 抛出错误时被调用。该钩子会接收两个参数：错误本身和包含错误来源信息的组件实例。
  }
}
</script>
 
<style scoped>
/* scoped 让style样式 局部生效*/
</style>