<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import pubsub from "pubsub-js";
export default {
  data() {
    return {};
  },
  oncerate() {
    //初始化方法
  },
  mounted() {
    // 下面订阅消息的事件内不能直接对this赋值 需要将回调函数写成箭头函数 保证回调函数内的this是vue实例
    const PubId = pubsub.subscribe("setLoginInfo", (masName, data) => {
      console.log(data);
      this.user = data;
      console.log("当前this.user的内容是", this.user);
      console.log("有人发布了hello消息 回调再执行了");
    });
    this.PubId = PubId;
  },
  methods: {},
  beforeDestroy() {
    pubsub.unsubscribe(this.PubId);
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}
</style>
