import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import MessAge from '../views/MessAge.vue'
import login from '../views/login.vue'
import tabs from '../views/tabs.vue'
import test from '../views/test.vue'
import agent from '../views/Order/agent.vue'

Vue.use(VueRouter)

const routes = [
  // 设置默认路由
  // { path: '/', component: HomeView },
  // 默认/login为首页
  { path: '/', name: 'login', component: login },
  { path: '/index', name: 'index', component: HomeView },
  { path: '/tabs', name: 'tabs', component: tabs },
  { path: '/test', name: 'test', component: test },
  { path: '/agent', name: 'agent', component: agent },
  { path: '/MessAge', name: 'MessAge', component: MessAge },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]




const router = new VueRouter({
  routes
})

export default router
